
.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem;
}

.wrapper {
    position: absolute;
    display: flex;
}

.bannerTextSection {
    display: flex;
    animation: swipe 60s linear infinite backwards;
}

.bannerImageSection {
    display: flex;
    animation: swipe 40s linear infinite backwards;
}

.bannerTextSection p {
    width: 480px;
    text-align: center;
}

.bannerTextSection p:nth-child(1) {
    width: 620px;
    text-align: center;

}

.bannerTextSection p:nth-child(2) {
    width: 770px;
    text-align: center;

}

.bannerTextSection p:nth-child(3) {
    width: 480px;
    text-align: center;

}

.bannerTextSection p:nth-child(4) {
    width: 460px;
    text-align: center;

}

.bannerTextSection p:nth-child(5) {
    width: 460px;
    text-align: center;

}

.bannerTextSection p:nth-child(6) {
    width: 650px;
    text-align: center;

}

.bannerImageImage {
    padding: 0 30px;
}

.bannerImageImage:last-of-type {
    padding-left: 0;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}
