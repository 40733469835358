:root {
    --color-principal: #007BFF;
    --color-texto: #242424;
    --color-boton-hover: #0056b3;
    --color-fondo-blanco: #FFFFFF;
    --color-gris-claro: #F8F8F8;
}

.gridContainer {
    background-color: var(--color-fondo-blanco);
    padding: 20px;
    transition: background 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
}

h3 {
    color: var(--color-texto);
    transition: color 0.3s ease, transform 0.3s ease;
}

h3:hover {
    color: var(--color-principal);
    transform: scale(1.05);
}

.custom-winahost-button {
    background-color: var(--color-principal);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-winahost-button:hover {
    background-color: var(--color-boton-hover);
    transform: scale(1.05);
}

.reviewBox {
    background-color: var(--color-fondo-blanco);
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--color-gris-claro);
    transition: transform 0.3s ease;
}

.reviewBox:hover {
    transform: scale(1.02);
}