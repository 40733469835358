.custom-winahost-button {
    background-color: var(--main-winahost-color) !important;
    color: white !important;
    border-radius: 50px !important;
    padding: 15px 25px !important;
    cursor: pointer !important;
}

.custom-winahost-button:hover {
    background-color: #016A6AFF !important;
}

.custom-winahost-button:disabled {
    background-color: #a9baba !important;
    opacity: 0.5;
}

.custom-winahost-button > span {
    color: white;
}



.custom-winahost-gray-button {
    background-color: var(--main-winahost-gray-color) !important; ;
    color: black !important;
    border: 1px solid #1d1d1d !important;
    border-radius: 50px !important;
    padding: 15px 25px !important;
    cursor: pointer !important;
}

.custom-winahost-gray-button:hover {
    background-color: #b6b6b6;
}

.custom-winahost-gray-button:disabled {
    color: #747474;
    background-color: #e5e5e5;
    opacity: 0.3;
    border: 1px solid #9a9a9a !important;
}


.custom-winahost-white-button {
    background-color: white;
    color: var(--main-winahost-color);
    border: 1px solid #1d1d1d;
    border-radius: 50px;
    padding: 15px 25px;
    cursor: pointer;
}

.custom-winahost-white-button:hover {
    background-color: #e0e0e0;
}
.custom-winahost-white-button > span {
    color: var(--main-winahost-color);}



.custom-warning-button {
    background-color: var(--main-custom-warning-button);
    color: black;
    border: 1.5px solid #000;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
}

.custom-warning-button > span {
    color: black;
}

.custom-warning-button:hover {
    background-color: var(--main-custom-warning-button-over);
}

