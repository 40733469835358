.whatsAppRow {
    justify-content: flex-end;
}

@media (max-width: 693px) {
    .whatsAppRow {
        padding-top: 20px;
        justify-content: center;
    }
}

.socialNetworkIcon {
    width: 40px; 
    height: auto; 
    margin: 1px 12px;
}

.footerEuropeanSponsorsImage {
    width: 50%;
}

@media (max-width: 989px) {
    .footerEuropeanSponsorsImage {
        width: 89.2%;
    }
}

@media (max-width: 899px) {
    .footerQuickLinksContainer {
        margin: 60px 0 !important;
    }

    .footerQuickLinksContainer hr {
        margin: 0 auto;
    }

    .footerQuickLinksParagraph {
        text-align: center !important;
    }

    .socialNetworkIcon {
        width: 40%;
    }
}

.footerWinahostLogo {
    width: 70%;
    max-width: 270px;
    height: auto;
}

@media (max-width: 600px) {
    .footerWinahostLogo {
        width: 70%;
        max-width: 200px;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .footerWinahostLogo {
        width: 60%;
        max-width: 220px;
    }
    .socialNetworkIcon {
        max-width: 35px;
        margin: 0 8px;
    }
}

@media (min-width: 900px) and (max-width: 1024px) {
    .footerWinahostLogo {
        width: 55%;
        max-width: 250px;
    }
    .socialNetworkIcon {
        max-width: 40px;
        margin: 0 10px;
    }
}

