:root {
    --background-base: rgb(245, 245, 248);
    --background-hover: rgba(0, 0, 0, .04);
}

.authBasePageBackground {
    background: linear-gradient(135deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px), 
                linear-gradient(225deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
    background-color: var(--background-base);
    background-size: 64px 128px;
    height: 100vh;
}
