.backgroundFormLanding {
    background-color: #91ca93;
    padding: 0 0 40px 0;
}

.elementor-shape[data-negative=false].elementor-shape-bottom {
    transform: rotate(180deg);
}

.elementor-shape-bottom {
    bottom: -1px;
}
.elementor-shape {
    overflow: hidden;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.elementor-shape-bottom svg {
    height: 577px;
}

.elementor-shape svg {
    display: block;
    width: calc(100% + 1px);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.elementor-shape-fill {
    fill: #00A8A80F;
}

.elementor-container {
    position: relative;
}

.elementor-container-background {
    position: relative;
}

.elementor-container-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 90vw;
    padding: 0 70px;
}

.elementor-image-content {
    width: 550px;
    position: absolute;
    top: 71px;
}

.contenedor {
    position: relative;
    display: inline-block;
}

.contenedor img {
    display: block;
    width: 100%;
    padding: 0 30px;
}

.contenedor .texto {
    position: absolute;
    width: 100%;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 70px;
}

.managementHeaderContactTitle {
    font-size: 2.3em !important;
}

.landingFormTitles {
    font-size: 1.5em !important;
    text-align: center;
}

@media (max-width: 1266px) {
    .managementHeaderContactTitle {
        font-size: 1.9em !important;
    }

    .landingFormTitles {
        font-size: 1.1em !important;
    }
}

@media (max-width: 430px) {
    .elementor-shape-bottom svg {
        height: 670px;
    }
}

/*.managementHeaderContactImageTitle {*/
/*    position: absolute;*/
/*    top: 550px;*/
/*    padding: 0 200px 0 200px ;*/
/*}*/

/*@media (max-width: 1266px) {*/
/*    .managementHeaderContactImageTitle {*/
/*        position: absolute;*/
/*        top: 560px;*/
/*        padding: 0 200px 0 200px ;*/
/*    }*/
/*}*/

/*@media (max-width: 900px) {*/
/*    .managementHeaderContactImageTitle {*/
/*        position: absolute;*/
/*        top: 536px;*/
/*        padding: 0 140px;*/
/*    }*/
/*}*/

/*@media (max-width: 700px) {*/
/*    .managementHeaderContactImageTitle p {*/
/*        margin-top: 30px;*/
/*        font-size: 1.3em;*/
/*    }*/
/*}*/

/*@media (max-width: 600px) {*/
/*    .managementHeaderContactImageTitle {*/
/*        position: unset;*/
/*        padding: 0;*/
/*        text-align: center;*/
/*    }*/

/*    .managementHeaderContactImageTitle p {*/
/*        margin-top: 0;*/
/*    }*/
/*}*/
