@import './customStyles/customButtons.css';

.statisticsWorkWithUs {
    padding-top: 46px;
}

.calculatorDegree {
    background: linear-gradient(75deg,  #fff 20%, rgba(0, 128, 128, 0.5) 100%, #fff 20%);
    padding: 40px 0;
}

@media (max-width: 899px) {
    .statisticsWorkWithUs {
        padding-top: unset;
    }
}

.tableInvoiceBorderExample {
    border: 2px solid #e0e0e0;
    border-radius: 30px;
    padding: 10px;
    background-color: #f7ffff;
}

.tableInvoiceExample {
    width: 100%;
}

.tableInvoiceExample th {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.tableInvoiceExample tr {
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    padding: 30px;

}

.tableInvoiceExample td {
    padding: 10px;
}

.tableInvoiceExample th:first-child, .tableInvoiceExample td:first-child {
    text-align: left;
    padding-left: 40px;
}

.tableInvoiceExample tr:last-child {
    border-bottom: none;
}

.adviceLastRow{
    text-align: right !important;
    padding-right: 60px !important;
}
