@import 'customStyles/customButtons.css';

/*

@import 'customStyles/customHeaders.css';

*/


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main-winahost-color: #008080;
    --main-winahost-gray-color: #e8e8e8;
    --main-footer-background-color: #151515;
    --main-custom-warning-button: #FFC700;
    --main-custom-warning-button-over: #debe46;
}

* {
    font-family: Poppins, serif !important;
}

.whatsapp-widget {
    display: inline-block;
    position: fixed;
    bottom: 1.3rem;
    right: 1.5rem;
    border-radius: 50%;
    background-color: #4DC247;
    box-shadow: 0 0 10px rgba(0,0,0,.5);
    cursor: pointer;
    opacity: 1;
    z-index: 998;
    animation-name: fade-in;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
}
.whatsapp-widget .icon {
    width: 50px;
    height: 50px;
    padding: 0.65rem 0.65em 0.65rem 0.65rem;
    fill: #fff;
}

.banner-whatsapp-widget {
    display: inline-block;
    position: fixed;
    right: 5rem;
    cursor: pointer;
    background-color: rgba(236, 236, 236, 0.96);
    border: 1px solid #1a1d20;
    padding: 10px;
    border-radius: 15px;
    width: 298px;
}

.banner-whatsapp-widget span {
    color: #1a1d20;
}

.avatar-banner-whatsapp-widget {
    position: fixed !important;
    bottom: 4rem;
    right: 21rem;
    z-index: 999;
    border: 1px solid black;
}

.close-banner-whatsapp-widget {
    display: inline-block;
    position: fixed !important;
    bottom: 4rem;
    right: 5rem;
    z-index: 999;
    border: 1px solid black;
    border-radius: 50px;
    width: 25px;
    background-color: #d1d2d4;
    text-align: center;
    cursor: pointer;
}

.close-banner-whatsapp-widget span {
    color: #1a1d20;
    font-weight: bold;
}

.fade-in {
    animation: fadeIn 1s ease-in-out;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 899px) {
    .MobileScreenLayout {
        margin-top: -25px !important;
    }
}
