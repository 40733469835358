.historiesCustomLeftBox {
    border-radius: 25px 0 0 25px;
    padding: 50px 30px;
}

.historiesCustomRightBox {
    border-radius: 0 25px 25px 0
}


@media (max-width: 899px) {
    .historiesCustomLeftBox {
        border-radius: 25px 25px 0 0
    }

    .historiesCustomRightBox {
        border-radius: 0 0 25px 25px
    }
}