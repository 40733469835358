.contactFormTitle {
    font-weight: 600 !important;
    font-size: 1.7em !important;
}

.contactFormSubtitle {
    font-size: 1.3em !important;
}

@media (max-width: 899px) {
    .contactFormTitle {
        font-size: 1.3em !important;
    }

    .contactFormSubtitle {
        font-size: .9em !important;
    }

}
