.managementHeaderTitle {
    font-size: 2.3em !important;
}

.managementHeaderImageTitle {
    position: absolute;
    top: 250px;
    padding: 0 200px 0 200px ;
}

.flip-car-title {
    font-size: 1em !important;
}

.flip-car-body {
    font-size: .9em !important;
}

.section360 {
    margin-top: 0;
}

@media (max-width: 1266px) {
    .managementHeaderImageTitle {
        position: absolute;
        top: 270px;
        padding: 0 200px 0 200px ;
    }

    .managementHeaderTitle {
        font-size: 1.9em !important;
    }
}

@media (max-width: 900px) {
    .managementHeaderImageTitle {
        position: absolute;
        top: 210px;
        padding: 0 70px 0 70px ;
    }
}

@media (max-width: 700px) {
    .managementHeaderImageTitle p {
        margin-top: 30px;
        font-size: 1.3em;
    }
}

@media (max-width: 600px) {
    .managementHeaderImageTitle {
        position: unset;
        padding: 0;
    }

    .managementHeaderImageTitle p {
        margin-top: 0;
    }
}



.flip-card {
    margin-top: 50px;
    height: 450px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-front img {
    border-radius: 20px
}

.flip-card-front-span {
    position: relative;
    bottom: 40px;
    text-align: left;
    padding: 10px;
    border-radius: 30px;
    background-color: var(--main-winahost-color);
    color: white;
}

/* Style the back side */
.flip-card-back {
    background-color: var(--main-winahost-color);
    height: auto;
    color: white;
    border-radius: 20px;
    padding: 15px;
    transform: rotateY(180deg);
}


@media (max-width: 1199px) {
    .flip-card {
        height: 680px;
    }

    .flip-car-title {
        font-size: 1.3em !important;
    }

    .flip-car-body {
        font-size: 1.3em !important;
    }

}

@media (max-width: 1060px) {
    .flip-card {
        height: 580px;
    }
}

@media (max-width: 999px) {
    .flip-card {
        height: 530px;
    }
}

@media (max-width: 899px) {
    .flip-card {
        height: 470px;
    }
}

@media (max-width: 799px) {
    .flip-card {
        height: 400px;
    }


    .flip-car-title {
        font-size: 1.3em !important;
    }

    .flip-car-body {
        font-size: 1em !important;
    }
}

@media (max-width: 699px) {
    .flip-card {
        height: 340px;
    }

    .flip-car-title {
        font-size: 1em !important;
    }

    .flip-car-body {
        font-size: .8em !important;
    }
}

@media (max-width: 599px) {
    .flip-card {
        height: 690px;
    }

    .flip-car-title {
        font-size: 1.2em !important;
    }

    .flip-car-body {
        font-size: 1em !important;
    }
}

@media (max-width: 499px) {
    .flip-card {
        height: 530px;
    }

    .section360 {
        margin-top: 120px;
    }
}

@media (max-width: 430px) {
    .flip-card {
        height: 470px;
    }
}
@media (max-width: 400px) {
    .flip-card {
        height: 420px;
    }
}

@media (max-width: 350px) {
    .flip-card {
        height: 365px;
    }

    .flip-car-title {
        font-size: 1em !important;
    }

    .flip-car-body {
        font-size: .8em !important;
    }
}
